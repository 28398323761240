import React, { useEffect, useRef, useState } from 'react';
import f3 from "family-chart";
import * as d3 from 'd3';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import "../family-chart.css";
import { Form } from './Form';
import { useSecureCode } from '../SecureCodeContext';

const mainId = '0';
const FamilyCreate = () => {
    const chartRef = useRef(null);
    const { secureCode } = useSecureCode();
    const [currRoot, setCurrRoot] = useState(null);

    useEffect(() => {
        const modal = document.body.appendChild(document.createElement("div"));
        modal.setAttribute("id", "form_modal");
        modal.setAttribute("class", "modal");
        window.M.Modal.init(modal, {});
    }, []);

    useEffect(() => fetchFamily(), []);

    const fetchFamily = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/families/${process.env.REACT_APP_FAMILY_ID}`, {
            method: 'GET',
            headers: {
                'x-code': secureCode
            }
        })
            .then((res) => res.json())
            .then((data) => {
                createChart(data.data?.data);
                localStorage.setItem("updatedAt", data.data?.updatedAt?.toString())
            })
            .catch((err) => console.error(err));
    }

    const updateFamily = async (familyTree) => {
        let data = JSON.stringify(familyTree);
        data = JSON.stringify(familyTree);
        const updatedAt = new Date(await localStorage.getItem("updatedAt"));
        const updateData = {
            data: { data, updatedAt }
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/families/${process.env.REACT_APP_FAMILY_ID}`, {
            method: 'PUT',
            body: JSON.stringify(updateData),
            headers: {
                'x-code': secureCode,
                'content-type': 'application/json'
            }
        }).then((resp) => {
            if (resp?.status == 409) {
                window.alert("Dữ liệu đã bị sửa đổi vui lòng reload lại trang!");
                window.location.reload();
            }
            return resp.json();
        }).then(data => {
            localStorage.setItem("updatedAt", data.data?.updatedAt?.toString())
        }).catch((err) => window.alert(err));
    }

    const createChart = (data) => {
        const cont = chartRef.current;
        const store = f3.createStore({
            data,
            node_separation: 270,
            level_separation: 150,
        });

        const card_dim = { w: 240, h: 70, text_x: 75, text_y: 15, img_w: 60, img_h: 60, img_x: 5, img_y: 5 }
        const card_display = cardDisplay()
        const card_edit = cardEditParams()
        const svg = f3.createSvg(cont)

        const Card = f3.elements.Card({
            store,
            svg,
            card_dim,
            card_display,
            mini_tree: true,
            cardEditForm,
            addRelative: f3.handlers.AddRelative({
                store, cont, card_dim, cardEditForm,
                labels: { father: 'Thêm cha', mother: 'Thêm mẹ', son: 'Thêm con trai', daughter: 'Thêm con gái', spouse: 'Thêm chồng/vợ' }
            }),
            onCardUpdate
        });

        function onCardUpdate(d) {
            const text = d3.select(this).select('.card-inner .card-text').append('text').attr('transform', `translate(${[75, 15]})`)

            text.append('tspan').attr('dy', 14).attr('font-weight', 'bold').attr('white-space', 'normal').text(`${d.data.data["fun"]}`)
            text.append('tspan').attr('x', 0).attr('dy', 20).attr('font-size', 14).text(`${d.data.data["dob"]} - ${d.data.data["dod"]}`)
        }

        store.setOnUpdate((props) => {
            f3.view(store.getTree(), svg, Card, props)
        });
        store.updateMainId(mainId); // Charles III
        store.updateTree({ initial: true });

        function cardEditForm(props) {
            const postSubmit = props.postSubmit;
            props.postSubmit = (ps_props) => {
                postSubmit(ps_props)
                updateFamily(store.getData())
            }
            const el = document.querySelector('#form_modal')
            const modal = window.M.Modal.getInstance(el)
            const edit = { el, open: () => modal.open(), close: () => modal.close() }
            Form({ ...props, card_edit, card_display, edit, config: { secureCode } })
        }

        function cardEditParams() {
            return [
                { type: 'text', placeholder: 'Họ và tên', key: 'fun' },
                { type: 'text', placeholder: 'Tên thường gọi', key: 'fan' },
                { type: 'text', placeholder: 'Năm sinh', key: 'dob' },
                { type: 'text', placeholder: 'Năm mất', key: 'dod' },
                { type: 'text', placeholder: 'Quê quán', key: 'ht' },
                { type: 'text', placeholder: 'Nghề nghiệp', key: 'jb' },
                { type: 'text', placeholder: 'Nơi ở hiện tại', key: 'add' },
                { type: 'text', placeholder: 'Số điện thoại', key: 'mb' },
                { type: 'text', placeholder: 'Facebook', key: 'fb' },
                { type: 'textarea', placeholder: 'Ghi chú', key: 'nt' },
            ]
        }

        function cardDisplay() {
            return []
        }

        // Random person button
        d3.select(cont)
            .append('button')
            .text('Xem lại')
            .attr('style', 'position: absolute; top: 10px; right: 10px; z-index: 1000; background: none; border: 1px solid #ccc;color: #ccc;')
            .on('click', () => {
                if (currRoot !== mainId) {
                    updateTreeWithNewMainPerson(mainId, true);
                    setCurrRoot(mainId);
                }
            });

        // Search dropdown
        const all_select_options = [];
        data.forEach((d) => {
            if (all_select_options.find((d0) => d0.value === d['id'])) return;
            all_select_options.push({ label: `${d.data["fun"]}`, value: d['id'] });
        });

        const searchCont = d3
            .select(cont)
            .append('div')
            .attr('style', 'position: absolute; top: 10px; left: 10px; width: 150px; z-index: 1000;')
            .on("focusout", () => {
                setTimeout(() => {
                    if (!searchCont.node().contains(document.activeElement)) {
                        updateDropdown([]);
                    }
                }, 200);
            });

        const searchInput = searchCont
            .append('input')
            .attr('style', 'width: 100%; background: none; border: none;border-bottom: 1px solid #ccc;outline: none;border-radius: 0;color: #ccc;')
            .attr('type', 'text')
            .attr('placeholder', 'Tìm kiếm')
            .on('focus', activateDropdown)
            .on('input', activateDropdown);

        const dropdown = searchCont
            .append('div')
            .attr('style', 'overflow-y: auto; max-height: 300px;')
            .attr('tabindex', '0')
            .on('wheel', (e) => e.stopPropagation());

        function activateDropdown() {
            const searchInputValue = searchInput.property('value');
            const filteredOptions = all_select_options.filter((d) =>
                d.label.toLowerCase().includes(searchInputValue.toLowerCase())
            );
            updateDropdown(filteredOptions);
        }

        function updateDropdown(filteredOptions) {
            dropdown
                .selectAll('div')
                .data(filteredOptions)
                .join('div')
                .attr('style', 'padding: 5px;cursor: pointer;border-bottom: .5px solid currentColor;')
                .on('click', (e, d) => {
                    updateTreeWithNewMainPerson(d.value, true);
                    updateDropdown([]);
                    setCurrRoot(d.value);
                })
                .text((d) => d.label);
        }

        function updateTreeWithNewMainPerson(person_id, animation_initial = true) {
            store.updateMainId(person_id);
            store.updateTree({ initial: animation_initial });
        }
    };

    const style = {
        backgroundColor: 'lightblue',
        color: 'white',
        padding: '20px',
    };

    return (
        <>
            <div
                id="FamilyChart"
                className="create f3"
                style={{ width: '100%', height: '900px', margin: 'auto', backgroundColor: 'rgb(33,33,33)', color: '#fff' }}
                ref={chartRef}
            ></div>
        </>
    );
};

export default FamilyCreate;
