import React, { useEffect, useRef, useState } from 'react';//
import f3 from "family-chart";
import * as d3 from 'd3';
import "./family-chart.css";
import Popup from './Popup';
import ProfileCard from './ProfileCard';
import { useSecureCode } from './SecureCodeContext';
import defaultAvatar from './assets/images/default-avatar.png'
import FullScreenImagePopup from './FullScreenImagePopup';

const mainId = '0';
const FamilyChart = () => {
  const chartRef = useRef(null);
  const [currRoot, setCurrRoot] = useState(null);
  const [currNode, setCurrNode] = useState(null);
  const { secureCode } = useSecureCode();

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupVisible2, setIsPopupVisible2] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const togglePopup2 = () => {
    setIsPopupVisible2(!isPopupVisible2);
  };

  useEffect(() => {
    // Fetch dữ liệu và tạo biểu đồ
    fetch(`${process.env.REACT_APP_API_URL}/api/families/${process.env.REACT_APP_FAMILY_ID}`, {
      method: 'GET',
      headers: {
        'x-code': secureCode
      }
    })
      .then((res) => res.json())
      .then((data) => createChart(data.data?.data))
      .catch((err) => console.error(err));
  }, []);

  const createChart = (data) => {
    const cont = chartRef.current;
    const store = f3.createStore({
      data,
      node_separation: 250,
      level_separation: 150,
    });

    setupHtmlSvg(cont)
    const getSvgView = () => cont.querySelector('svg .view')
    const getHtmlSvg = () => cont.querySelector('#htmlSvg')
    const getHtmlView = () => cont.querySelector('#htmlSvg .cards_view')

    const svg = f3.createSvg(cont, { onZoom: f3.htmlHandlers.onZoomSetup(getSvgView, getHtmlView) })
    f3.htmlHandlers.setupHtmlSvg(getHtmlSvg)

    store.setOnUpdate(props => {
      const tree = store.getTree()

      props = Object.assign({}, props || {}, { cardHtml: getHtmlSvg() })
      f3.view(tree, svg, Card(), props || {})
    })

    store.updateMainId(mainId)
    store.updateTree({ initial: true })

    function Card() {
      return function (d) {
        this.innerHTML = `
          <div class="cardd" style="transform: translate(-50%, -50%)">
            ${getCardInnerImage(d)}
          </div>`;
        this.querySelector(".cardd").addEventListener("click", (e) =>
          onCardClick(e, d)
        );
        this.mini_tree = true
      };

      function onCardClick(e, d) {
        setCurrNode(d.data.data);
        togglePopup();
      }

      function getCardInnerImage(d) {
        return `
          <div class="card-image ${getClassList(d).join(" ")}">
            <img src="${d.data.data.avatar ? d.data.data.avatar : defaultAvatar}" alt="avatar" />
            <div class="card-label">${d.data.data.fun}</div>
          </div>`;
      }

      function getClassList(d) {
        const classList = [];
        if (d.data.data.gender === "M") classList.push("card-male");
        else if (d.data.data.gender === "F") classList.push("card-female");
        else classList.push("card-genderless");

        if (d.data.main) classList.push("card-main");

        return classList;
      }
    }

    // Random person button
    d3.select(cont)
      .append('button')
      .html('&#x21bb;')
      .attr('style', 'position: absolute; top: 15px; right: 15px; z-index: 1000; background: none; border: 1px solid red;color: red; padding: 5px 10px;')
      .attr('class', 'reset-btn')
      .on('click', () => {
        if (currRoot !== mainId) {
          updateTreeWithNewMainPerson(mainId, true);
          setCurrRoot(mainId);
        }
      });

    d3.select(cont)
      .append('button')
      .html('&#x1F5BC;')
      .attr('style', 'position: absolute; top: 15px; right: 55px; z-index: 1000; background: none; border: 1px solid red;color: red; padding: 5px 9px;')
      .attr('class', 'image-btn')
      .on('click', () => {
        togglePopup2()
      });

    // Search dropdown
    const all_select_options = [];
    data.forEach((d) => {
      if (all_select_options.find((d0) => d0.value === d['id'])) return;
      all_select_options.push({ label: `${d.data["fun"]}`, value: d['id'] });
    });

    const searchCont = d3
      .select(cont)
      .append('div')
      .attr('style', 'position: absolute; top: 10px; left: 15px; width: 150px; z-index: 1000; color: red')
      .attr('class', 'search-container')
      .on("focusout", () => {
        setTimeout(() => {
          if (!searchCont.node().contains(document.activeElement)) {
            updateDropdown([]);
          }
        }, 200);
      });

    const searchInput = searchCont
      .append('input')
      .attr('style', 'width: 100%; background: none; border: none;border-bottom: 1px solid red;outline: none;border-radius: 0;color: red;')
      .attr('type', 'text')
      .attr('placeholder', 'Tìm kiếm')
      .attr('class', 'search-input')
      .on('focus', activateDropdown)
      .on('input', activateDropdown);

    searchCont
      .append('button')
      .attr('class', 'hamburger-menu')
      .attr('style', 'font-size: 24px; background: none; border: none; cursor: pointer; padding: 0px; color: red')
      .text('☰') // Icon của nút hamburger
      .on('click', () => {
        const searchInput = document.querySelector('.search-input');
        if (searchInput.style.display === 'block') {
          searchInput.style.display = 'none';
        } else {
          searchInput.style.display = 'block';
        }

        const mobileMenu = document.querySelector('.reset-btn');
        if (mobileMenu.style.display === 'block') {
          mobileMenu.style.display = 'none';
        } else {
          mobileMenu.style.display = 'block';
        }

        const imageButton = document.querySelector('.image-btn');
        if (imageButton.style.display === 'block') {
          imageButton.style.display = 'none';
        } else {
          imageButton.style.display = 'block';
        }
      });

    const dropdown = searchCont
      .append('div')
      .attr('style', 'overflow-y: auto; max-height: 300px; pointer-events: auto;')
      .attr('tabindex', '0')
      .on('wheel', (e) => e.stopPropagation()).on('touchmove', (e) => e.stopPropagation());

    function activateDropdown() {
      const searchInputValue = searchInput.property('value');
      const filteredOptions = all_select_options.filter((d) =>
        d.label.toLowerCase().includes(searchInputValue.toLowerCase())
      );
      updateDropdown(filteredOptions);
    }

    function updateDropdown(filteredOptions) {
      dropdown
        .selectAll('div')
        .data(filteredOptions)
        .join('div')
        .attr('style', 'padding: 5px;cursor: pointer;border-bottom: .5px solid currentColor;')
        .on('click', (e, d) => {
          updateTreeWithNewMainPerson(d.value, true);
          updateDropdown([]);
          setCurrRoot(d.value);
        })
        .text((d) => d.label);
    }

    function updateTreeWithNewMainPerson(person_id, animation_initial = true) {
      store.updateMainId(person_id);
      store.updateTree({ initial: animation_initial });
    }

    function setupHtmlSvg(cont) {
      cont.style.overflow = 'hidden'
      const cardHtml = d3.select(cont).append('div').attr('id', 'htmlSvg')
        .attr('style', 'position: absolute; width: 100%; height: 100%; z-index: 2; top: 0; left: 0')
      cardHtml.append('div').attr('class', 'cards_view').style('transform-origin', '0 0')
    }
  };

  return (
    <>
      <div
        id="FamilyChart"
        className="home f3"
        style={{ width: '100%', height: '900px', margin: 'auto', backgroundColor: 'rgb(33,33,33)', color: '#fff' }}
        ref={chartRef}
      ></div>
      <Popup isVisible={isPopupVisible}>
        <ProfileCard onClose={togglePopup} data={currNode} />
      </Popup>
      <Popup isVisible={isPopupVisible2}>
        <FullScreenImagePopup onClose={togglePopup2} />
      </Popup>
    </>
  );
};

export default FamilyChart;
