import React from 'react';

const Popup = ({ isVisible, children }) => {
  if (!isVisible) return null; // Không hiển thị nếu popup không được bật

  return (
    <div style={styles.overlay}>
      <div style={styles.popupContainer}>
        {children}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Lớp phủ mờ
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1001,
  }
};

export default Popup;
