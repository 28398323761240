import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FamilyChart from './FamilyChart';
import Home from './Home';
import FamilyCreate from './create-tree/FamilyCreate';
import { SecureCodeProvider, useSecureCode } from './SecureCodeContext';

const AppRoutes = () => {
  const { isAuthenticated, authenticate } = useSecureCode();

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? (
          <FamilyChart />
        ) : (
          <Home onAuthenticate={authenticate} />
        )}
      />
      <Route
        path="/xhd96eap"
        element={isAuthenticated ? (
          <FamilyCreate />
        ) : (
          <Home onAuthenticate={authenticate} />
        )}
      />
    </Routes>
  );
};

const App = () => {
  return (
    <SecureCodeProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </SecureCodeProvider>
  );
};

export default App;
