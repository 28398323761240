import React, { createContext, useContext, useState } from 'react';

// Tạo Context
const SecureCodeContext = createContext();

// Tạo Provider
export const SecureCodeProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [secureCode, setSecureCode] = useState(null);

  // Hàm để cập nhật secureCode
  const authenticate = (code) => {
    setSecureCode(code);
    setIsAuthenticated(true);
  };

  return (
    <SecureCodeContext.Provider value={{ isAuthenticated, secureCode, authenticate }}>
      {children}
    </SecureCodeContext.Provider>
  );
};

// Custom hook để sử dụng SecureCodeContext
export const useSecureCode = () => {
  const context = useContext(SecureCodeContext);
  if (!context) {
    throw new Error('useSecureCode must be used within a SecureCodeProvider');
  }
  return context;
};
