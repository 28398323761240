import React, { useState } from 'react';
import './Home.css';

const Home = ({ onAuthenticate }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAuthentication(inputValue);
  };

  const handleAuthentication = async (code) => {
    try {
      // Gọi API để kiểm tra mã bảo mật
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/scodes/verify/${code}`, {
        method: 'GET',
      });
      const data = await response.json();

      if (data.data) {
        onAuthenticate(code); // Chuyển sang trang FamilyChart
      } else {
        alert('Mã bảo mật không chính xác!');
      }
    } catch (error) {
      console.error('Lỗi khi kiểm tra mã bảo mật:', error);
      alert('Đã xảy ra lỗi. Vui lòng thử lại.');
    }
  };

  return (
    <div className="home-container">
      <div className="input-container">
        <h5>Nhập mã bảo mật</h5>
        <form onSubmit={handleSubmit}>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
            style={{textAlign: 'center'}}
          />
          <button type="submit">Xác nhận</button>
        </form>
      </div>
    </div>
  );
};

export default Home;
