import React from "react";
import defaultAvatar from './assets/images/avatar-default.svg'

// Định nghĩa CSS thành một đối tượng
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
    minHeight: "100vh",
    padding: "10px",
  },
  card: {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    boxSizing: "border-box",
    width: "95%",
    maxWidth: "500px",
    minWidth: "350px",
    flexGrow: 1
  },
  card2: {
    position: "relative",
    backgroundColor: "gray",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    boxSizing: "border-box",
    width: "95%",
    maxWidth: "500px",
    minWidth: "350px",
    flexGrow: 1
  },
  header: {
    textAlign: "center",
    marginBottom: "10px",
  },
  avatarContainer: {
    position: "relative",
  },
  avatar: {
    border: "double 5px transparent",
    borderRadius: "50%",
    backgroundImage: "linear-gradient(white, white), linear-gradient(to right, #ee00ff,#fbff00)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    width: "150px",
    height: "150px",
    maxWidth: "150px",
    objectFit: "cover",
  },
  avatar2: {
    border: "double 5px transparent",
    borderRadius: "50%",
    backgroundImage: "linear-gradient(white, white), linear-gradient(to right, #000,#000)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    width: "150px",
    height: "150px",
    maxWidth: "150px",
    objectFit: "cover",
  },
  name: {
    margin: "0",
    fontSize: "24px",
  },
  location: {
    margin: "5px 0 0",
    color: "#666",
    fontSize: "14px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#666",
  },
  content: {
    marginTop: "20px",
    width: "100%",
  },
  detailRow: {
    display: "flex",
    marginBottom: "10px",
  },
  label: {
    flexBasis: "35%",
    fontWeight: "bold",
    fontSize: "14px",
  },
  value: {
    flexBasis: "65%",
    fontSize: "14px",
  },
};

const ProfileCard = ({ onClose, data }) => {
  const profile1 = [
    { label: "Nơi ở HT", value: data.add || '-' },
    { label: "Nghề nghiệp", value: data.jb || '-' },
    { label: "Giới tính", value: data.gender == 'M' ? 'Nam' : 'Nữ' },
    { label: "Năm sinh", value: data.dob || '-' },
    { label: "Nguyên quán", value: data.ht || '-' },
    { label: "Số điện thoại", value: data.mb || '-' },
    { label: "Mạng xã hội", value: data.fb || '-' },
  ];

  const profile2 = [
    { label: "Năm sinh", value: data.dob || '-' },
    { label: "Năm mất", value: data.dod || '-' },
    { label: "Giới tính", value: data.gender == 'M' ? 'Nam' : 'Nữ' },
    { label: "Nguyên quán", value: data.ht || '-' },
    { label: "Mộ phần tại", value: data.add || '-' },
  ];

  const infos = data.dod ? profile2 : profile1
  const getValueFormat = (item) => {
    if (item.label == "Mạng xã hội" && item.value != '-') {
      return <span style={styles.value}><a href={item.value} target="_blank">Facebook</a></span>
    } else {
      return <span style={styles.value}>{item.value}</span>
    }
  }

  return (
    <div style={styles.container}>
      <div style={data.dod ? styles.card2 : styles.card}>
        <div style={styles.header}>
          <div style={styles.avatarContainer}>
            <img src={data?.avatar || defaultAvatar} alt="Profile" style={data.dod ? styles.avatar2 : styles.avatar} />
            <div style={{paddingTop: "10px"}}>
              <h2 style={styles.name}>{data.fun}</h2>
              {data.fan && <p style={styles.location}>{data.fan}</p>}
            </div>
          </div>
          <button style={styles.closeButton} onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </div>
        <hr />
        <div style={styles.content}>
          <div>
            {infos.map((item, index) => (
              <div key={index} style={styles.detailRow}>
                <span style={styles.label}>{item.label}</span>
                {getValueFormat(item)}
              </div>
            ))}
          </div>
        </div>
        {data.nt}
      </div>
    </div>
  );
};

export default ProfileCard;
