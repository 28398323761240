import React, { useState } from "react";

const FullScreenImagePopup = ({ onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose} // Đóng popup khi click vào nền
    >
      <img
        src={"https://api.hnts.site/uploads/ad16b937f97d43231a6c_d6713b3978.jpg"}
        alt="Popup"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

export default FullScreenImagePopup;
