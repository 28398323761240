export function Form({ datum, rel_datum, store, rel_type, card_edit, postSubmit, edit: { el, open, close }, config }) {
  setupFromHtml();
  open();

  function setupFromHtml() {
    el.innerHTML = (`
      <div class="modal-content">
        <form>
          <div>
            <div style="text-align: left">
              <span style="display: ${datum.to_add || !!rel_datum ? 'none' : null}; float: right; cursor: pointer" class="red-text delete">Xóa</span>
            </div>
            <div style="padding-bottom: 20px">
              <label><input type="radio" name="gender" value="M" ${datum.data.gender === 'M' ? 'checked' : ''}><span>Nam</span></label>
              <label><input type="radio" name="gender" value="F" ${datum.data.gender === 'F' ? 'checked' : ''}><span>Nữ</span></label><br>
            </div>
            <label>Hình đại diện</label>
            <input type="file" class="upload-avatar" accept="image/*"><div id="preview"></div>
          </div>
          ${getEditFields(card_edit)}
          ${(rel_type === "son" || rel_type === "daughter") ? otherParentSelect() : ''}
          <br><br>
          <div style="text-align: center">
            <button type="submit" class="btn">Lưu/Cập nhật</button>
          </div>
        </form>
      </div>
    `)
    el.querySelector("form").addEventListener('submit', submitFormChanges)
    el.querySelector(".delete").addEventListener('click', deletePerson)
    el.querySelector(".upload-avatar").addEventListener('change', uploadAvatar)
  }

  function otherParentSelect() {
    const data_stash = store.getData();
    return (`
      <div hidden>
        <label>Select other</label>
        <select name="other_parent" style="display: block">
          ${(!rel_datum.rels.spouses || rel_datum.rels.spouses.length === 0)
        ? ''
        : rel_datum.rels.spouses.map((sp_id, i) => {
          const spouse = data_stash.find(d => d.id === sp_id)
          return (`<option value="${sp_id}" ${i === 0 ? 'selected' : ''}>hidden</option>`)
        }).join("\n")}
          <option value="${'_new'}">NEW</option>
        </select>
      </div>
    `)
  }

  function submitFormChanges(e) {
    e.preventDefault()
    const form_data = new FormData(e.target)
    form_data.forEach((v, k) => datum.data[k] = v)

    close()
    postSubmit({ data: datum })
  }

  function deletePerson() {
    const userConfirmed = window.confirm("Bạn có chắc chắn muốn xóa dữ liệu này?");

    if (userConfirmed) {
      // Xóa dữ liệu hoặc gọi API
      close()
      postSubmit({ delete: true, data: datum })
    }
  }

  function uploadAvatar(e) {
    const file = e.target.files[0]; // Lấy file đầu tiên từ input
    if (file) {
      const formData = new FormData();
      formData.append("files", file); // Đưa file vào FormData

      try {
        // Gửi file lên server bằng fetch
        fetch(`${process.env.REACT_APP_API_URL}/api/upload`, {
          method: "POST",
          body: formData,
          headers: {
            'x-code': config.secureCode,
          }
        }).then(async (response) => {
          if (response.ok) {
            const result = await response.json();
            console.log("Upload thành công:", result);

            // Lấy URL hình ảnh từ API Strapi
            const imageUrl = result[0].url; // Đường dẫn URL của hình ảnh được trả về
            displayImage(imageUrl);
          } else {
            console.error("Lỗi khi upload:", response.statusText);
          }
        })
      } catch (error) {
        console.error("Lỗi:", error);
      }
    }
  }

  // Hàm hiển thị hình ảnh
  function displayImage(url) {
    const imgUrl = `${process.env.REACT_APP_API_URL}${url}`;
    const previewContainer = document.querySelector("#preview");
    previewContainer.innerHTML = ""; // Xóa hình ảnh cũ (nếu có)
    const img = document.createElement("img");
    img.src = url.startsWith("http") ? url : imgUrl; // Xử lý URL đầy đủ
    img.alt = "Uploaded Image";
    img.style.height = "50px"; // Kích thước ảnh
    img.style.marginTop = "10px";
    previewContainer.appendChild(img); // Thêm hình ảnh vào container
    datum.data['avatar'] = imgUrl
  }

  function getEditFields(card_edit) {
    return card_edit.map(d => (
      d.type === 'text'
        ? `<label>${d.placeholder}</label><input type="text" name="${d.key}" value="${datum.data[d.key] || ''}">`
        : d.type === 'textarea'
          ? `<label>${d.placeholder}</label><textarea class="materialize-textarea" name="${d.key}" rows="3">${datum.data[d.key] || ''}</textarea>`
          : ''
    )).join('\n')
  }
}